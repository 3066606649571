//import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { api } from '../../api/index'
import { useForm } from '../../hooks/useForm'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCountdown } from 'src/hooks/useCountdown'
import rocket from '../../Imgs/rocket.png'
import chef from '../../Imgs/chef.png'
import { Modal } from 'antd'
import backgroudWeOffer from '../../Imgs/sendsuccess.png'
import Sponsors from 'src/Components/Sponsors'

const formFields = {
  foodCategoryId: '',
  storeName: '',
  category: '',
  ownerName: '',
  city: '',
  phoneCode: '+57',
  phoneNumber: '',
  email: '',
  conditions: false,
  countryId: '',
  countryCode: 'CO',
  flag: 'https://pideypasa-s3.s3.amazonaws.com/Images/Flags/colombia.png',
  countries: await api.getCountries(),
  categories: await api.getCategories(),
  captcha: ''
}

const suscriptionFields = {
  email: '',
  name: ''
}

const successMsg = 'Se ha enviado la solicitud con éxito.'
const errorMsg =
  '¡Algo ha fallado! Revise los campos del formulario e intente de nuevo.'

const launchDateMs = 1735689600000

const SignupRestaurant = () => {
  const [open, setOpen] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  //const [messageModalSub, setMessageModalSub] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [isDisabledSub, setIsDisabledSub] = useState(false)
  const [hasErrors, setHasErrors] = useState({})
  const { formState, onInputChange, onResetForm } = useForm(formFields)
  const {
    formState: formStateSub,
    onInputChange: inputChangeSub,
    onResetForm: resetFormSub
  } = useForm(suscriptionFields)
  const [days, hours, minutes, seconds] = useCountdown(launchDateMs)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsDisabled(true)
    setHasErrors({})
    let conditionsMsg
    let foodCategoryMsg
    let storeNameMsg
    let ownerNameMsg
    let countryMsg
    let cityMsg
    let phoneNumberMsg
    let emailMsg
    let captchaMsg

    if (formState.conditions) {
      const register = await api.postRegister(formState)
      setIsDisabled(false)
      if (register?.status === 400) {
        setMessageModal(errorMsg)
        foodCategoryMsg = await register.data.message.find(
          (err) => err.property === 'foodCategoryId'
        )?.message

        storeNameMsg = await register.data.message.find(
          (err) => err.property === 'storeName'
        )?.message

        ownerNameMsg = register.data.message.find(
          (err) => err.property === 'ownerName'
        )?.message

        countryMsg = register.data.message.find(
          (err) => err.property === 'countryId'
        )?.message

        cityMsg = register.data.message.find(
          (err) => err.property === 'city'
        )?.message

        phoneNumberMsg = register.data.message.find(
          (err) => err.property === 'phoneNumber'
        )?.message

        emailMsg = register.data.message.find(
          (err) => err.property === 'email'
        )?.message
      }

      if (register?.status === 201) {
        setMessageModal(successMsg)
        setOpen(true)
        onResetForm()
      }
    } else {
      setMessageModal(errorMsg)
      setIsDisabled(false)
      if (!formState.conditions) {
        conditionsMsg = 'Debe aceptar los términos y condiciones.'
      }
    }

    setHasErrors({
      foodCategoryId: foodCategoryMsg || null,
      storeName: storeNameMsg || null,
      ownerName: ownerNameMsg || null,
      countryId: countryMsg || null,
      city: cityMsg || null,
      phoneNumber: phoneNumberMsg || null,
      email: emailMsg || null,
      conditions: conditionsMsg || null,
      captcha: captchaMsg || null
    })
    const container = document.getElementById('sendButton')
    container.scrollIntoView({ behavior: 'smooth' })
    //console.log(register)
  }

  const handleSuscription = async (e) => {
    e.preventDefault()
    let emailMsg
    let nameMsg
    const register = await api.postNewsletter(formStateSub)
    setIsDisabledSub(true)
    if (register?.status === 400) {
      //setMessageModalSub(errorMsg)
      emailMsg = register.data.message.find(
        (err) => err.property === 'email'
      )?.message

      nameMsg = register.data.message.find(
        (err) => err.property === 'name'
      )?.message
    }

    if (register?.status === 201) {
      //setMessageModalSub('Te has suscrito correctamente.')
      setOpen(true)
      resetFormSub()
    }

    setHasErrors({
      emailSub: emailMsg || null,
      nameSub: nameMsg || null
    })

    setIsDisabledSub(false)
    const container = document.getElementById('emailSub')
    container.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Modal
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={900}
        maskClosable={false}
        okText={null}
        okButtonProps={{ style: { display: 'none' } }} // Oculta el botón "Ok"
        cancelButtonProps={{ style: { display: 'none' } }} // Oculta el botón de cancelación
      >
        <div className="flex flex-col items-center space-y-4">
          <div className="flex">
            <img
              className="flex"
              height={250}
              width={250}
              src={backgroudWeOffer}
              alt="backgroudWeOffer"
            />
          </div>
          <div className="flex">
            <h1 className="text-Main text-center text-2xl font-bold">
              Envío exitoso
            </h1>
          </div>
          <div className="flex w-72">
            <p className="textoGeneral text-center">
              Hemos recibido tu solicitud, muy pronto te contactaremos.
            </p>
          </div>
        </div>
      </Modal>
      <motion.div
        initial={{ x: -400 }}
        transition={{ duration: 1.2 }}
        animate={{ x: 0 }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row bg-[#C44139] h-[150px] w-full items-center justify-center space-x-3 sm:space-x-12 lg:space-x-24">
            <div className="flex-start flex-col px-1">
              <img
                alt="rocket"
                src={rocket}
                height={50}
                width={100}
                className="mx-auto"
              />
              <span className="text-white text-center text-[0.8rem] md:text-[1.5rem]">
                ¡Muy pronto!
              </span>
            </div>
            <div className="flex flex-col text-center">
              <span className="text-white font-bold text-[1.2rem] sm:text-[2rem] lg:text-[60px]">
                {days}
              </span>
              <span className="text-white text-[0.8rem] md:text-[1.5rem] mt-2">
                Días
              </span>
            </div>
            <div className="flex flex-col text-center">
              <span className="text-white font-bold text-[1.2rem] sm:text-[2rem] lg:text-[60px]">
                {hours}
              </span>
              <span className="text-white text-[0.8rem] md:text-[1.5rem] mt-2">
                Horas
              </span>
            </div>
            <div className="flex flex-col text-center">
              <span className="text-white font-bold text-[1.2rem] sm:text-[2rem] lg:text-[60px]">
                {minutes}
              </span>
              <span className="text-white text-[0.8rem] md:text-[1.5rem] mt-2">
                Minutos
              </span>
            </div>
            <div className="flex flex-col text-center">
              <span className="text-white font-bold text-[1.2rem] sm:text-[2rem] lg:text-[60px]">
                {seconds}
              </span>
              <span className="text-white text-[0.8rem] md:text-[1.5rem] mt-2">
                Segundos
              </span>
            </div>
          </div>

          <div className="mx-auto mb-20">
            <div className="flex flex-row items-center">
              <div className="w-full mt-10">
                <h1 className="tituloPrincipal mx-auto text-center">
                  Suscríbete como cliente y hazte{' '}
                  <span className="text-Main">Pionero</span> ahora!
                </h1>
                <p className="text-lg text-center mt-[20px]">
                  Obtén tu acceso anticipado:
                </p>
                <form className="p-4 mt-4 w-full" onSubmit={handleSuscription}>
                  <input
                    id="nameSub"
                    name="name"
                    onChange={inputChangeSub}
                    value={formStateSub.name}
                    max="200"
                    className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                      hasErrors.nameSub ? 'border-red-600' : 'border-none'
                    }`}
                    type="text"
                    placeholder="Tu nombre:"
                  />
                  {hasErrors.nameSub && (
                    <p className="text-sm">
                      {
                        <strong className="text-red-500">
                          {hasErrors.nameSub}
                        </strong>
                      }
                    </p>
                  )}

                  <input
                    id="emailSub"
                    name="email"
                    onChange={inputChangeSub}
                    value={formStateSub.email}
                    max="200"
                    className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                      hasErrors.emailSub ? 'border-red-600' : 'border-none'
                    }`}
                    type="text"
                    placeholder="Tu email:"
                  />
                  {hasErrors.emailSub && (
                    <p className="text-sm">
                      {
                        <strong className="text-red-500">
                          {hasErrors.emailSub}
                        </strong>
                      }
                    </p>
                  )}

                  <button
                    id="sendButtonSub"
                    className={`mt-12 w-full h-12 px-2 text-white text-2xl font-bold md:p-2 rounded-full mb-8 md:mb-4 ${
                      isDisabledSub ? 'bg-gray-500' : 'bg-Main'
                    }`}
                    type="submit"
                    disabled={isDisabledSub}
                  >
                    <p className="text-lg text-white">¡Únete ahora!</p>
                  </button>
                  {/* <p className="text-sm">
                    {
                      <strong className="text-[#47C257]">
                        {'Te has suscrito correctamente.' === messageModalSub &&
                          messageModalSub}
                      </strong>
                    }
                  </p> */}
                </form>
              </div>
            </div>
          </div>
          <div className="border-b-4 w-[90%] mx-auto mb-4"></div>

          <div className="p-4 w-full container mx-auto">
            <img
              id="chef"
              alt="chef"
              src={chef}
              className="mx-auto mb-6"
              height={491}
              width={491}
            />
            <h1 className="tituloPrincipal text-center">
              Tengo un <span className="text-Main">restaurante </span> y quiero
              más información
            </h1>
            <p className="lg:px-60 xl:px-80 text-lg text-center mt-[20px]">
              Complete la siguiente información:
            </p>
            <form
              // onSubmit={infoStoreSubmit}
              className="w-full mt-4 mb-4 p-2 lg:px-60 xl:px-80"
              // method="POST"
              onSubmit={handleSubmit}
            >
              <select
                name="foodCategoryId"
                onChange={onInputChange}
                value={formState.foodCategoryId}
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.foodCategoryId ? 'border-red-600' : 'border-none'
                }`}
                //defaultValue=""
              >
                <option value="" disabled>
                  Elegir una categoría
                </option>
                {formState.categories?.map((item) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {hasErrors.foodCategoryId && (
                <p className="text-sm">
                  <strong className="text-red-500">
                    {hasErrors.foodCategoryId}
                  </strong>
                </p>
              )}

              <input
                name="storeName"
                onChange={onInputChange}
                value={formState.storeName}
                max="200"
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.storeName ? 'border-red-600' : 'border-none'
                }`}
                type="text"
                placeholder="Nombre del restaurante"
              />
              {hasErrors.storeName && (
                <p className="text-sm">
                  {
                    <strong className="text-red-500">
                      {hasErrors.storeName}
                    </strong>
                  }
                </p>
              )}
              <input
                name="ownerName"
                value={formState.ownerName}
                onChange={onInputChange}
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.ownerName ? 'border-red-600' : 'border-none'
                }`}
                type="text"
                placeholder="Nombre del propietario"
              />
              {hasErrors.ownerName && (
                <p className="text-sm">
                  {
                    <strong className="text-red-500">
                      {hasErrors.ownerName}
                    </strong>
                  }
                </p>
              )}
              <select
                name="countryId"
                onChange={onInputChange}
                value={formState.countryId}
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.countryId ? 'border-red-600' : 'border-none'
                }`}
                //defaultValue=""
              >
                <option value="" disabled>
                  Elegir un país
                </option>
                {formState.countries?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {hasErrors.countryId && (
                <p className="text-sm">
                  {
                    <strong className="text-red-500">
                      {hasErrors.countryId}
                    </strong>
                  }
                </p>
              )}
              <input
                name="city"
                value={formState.city}
                onChange={onInputChange}
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.city ? 'border-red-600' : 'border-none'
                }`}
                type="text"
                placeholder="Ciudad"
              />
              {hasErrors.city && (
                <p className="text-sm">
                  {<strong className="text-red-500">{hasErrors.city}</strong>}
                </p>
              )}
              <div className="relative flex flex-nowrap bg-gray-100 mt-5">
                <div className="flex justify-end w-full">
                  <label
                    htmlFor="phoneNumber"
                    className={`relative rounded block w-full ${
                      hasErrors.phoneNumber && 'border border-red-600'
                    }`}
                  >
                    <img
                      src={formState.flag}
                      className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3"
                      alt=""
                    />
                    <span className="absolute ml-[50px] mt-[16px]">
                      {formState.phoneCode}
                    </span>
                    <input
                      name="phoneNumber"
                      value={formState.phoneNumber}
                      onChange={onInputChange}
                      className="p-4 rounded w-full pl-[80px] bg-transparent border-none"
                      placeholder="Teléfono"
                    />
                  </label>
                </div>
              </div>
              {hasErrors.phoneNumber && (
                <p className="text-sm">
                  {
                    <strong className="text-red-500">
                      {hasErrors.phoneNumber}
                    </strong>
                  }
                </p>
              )}
              <input
                name="email"
                value={formState.email}
                onChange={onInputChange}
                className={`p-4 bg-gray-100 w-full rounded mt-5 ${
                  hasErrors.email ? 'border-red-600' : 'border-none'
                }`}
                type="text"
                placeholder="Email"
              />
              {hasErrors.email && (
                <p className="text-sm">
                  {<strong className="text-red-500">{hasErrors.email}</strong>}
                </p>
              )}

              <div className="flex flex-nowrap mt-8">
                <input
                  name="conditions"
                  checked={formState.conditions}
                  onChange={onInputChange}
                  className="w-20 h-16 p-4"
                  type="checkbox"
                />

                <span className="textoGeneral ml-4">
                  Aceptas los términos de uso de Tecnicall Colombia S.A.S. y
                  reconoces que leíste la
                  <Link to="/politica-privacidad">
                    <b className="text-[#3682F4]"> Política de privacidad</b>
                  </Link>
                  .
                </span>
              </div>
              {hasErrors.conditions && (
                <p className="text-sm">
                  {
                    <strong className="text-red-500">
                      {hasErrors.conditions}
                    </strong>
                  }
                </p>
              )}

              <button
                id="sendButton"
                className={`mt-12 w-full h-12 px-2 text-white text-2xl font-bold md:p-2 rounded-full mb-8 md:mb-4 ${
                  isDisabled ? 'bg-gray-500' : 'bg-Main'
                }`}
                type="submit"
                disabled={isDisabled}
              >
                <p className="text-lg text-white">Estoy interesado</p>
              </button>
              <p className="text-sm">
                {
                  <strong
                    className={`${
                      messageModal === errorMsg
                        ? 'text-red-600'
                        : 'text-[#47C257]'
                    } `}
                  >
                    {messageModal}
                  </strong>
                }
              </p>
            </form>
          </div>
        </div>
        <Sponsors />
      </motion.div>
    </>
  )
}

export default SignupRestaurant
